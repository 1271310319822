import "../styles/promo.css";

function PromoCard() {
    return (
        <div className="promocard">
            <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'left', marginLeft: '10px' }}> Seasonal Special </h1>
            <div className="promoimage">
                <div>
                    <h1> RAFFLE TO WIN </h1>
                    <h3 style={{ color: 'white', marginTop: 0 }}> $2,000 LOUIS VUITTON BAG </h3>
                    <p> Eligibility: You will be given one raffle ticket at the checkout when you each spend $50.0 or more. One raffle ticket per customer per visit. The more you visit the more chance you win. Ticket will be drawn on Jan 03, 2025.</p>
                </div>
            </div>
        </div>
    );
}

export default PromoCard;
