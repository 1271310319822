import { motion} from "framer-motion";
import {useRef } from "react";
import "../styles/service.css";
import InfoCard from "../components/infoCard";
import nail2 from "../artifacts/nail02.png";
import nail11 from "../artifacts/nail11.jpeg"
import nail20 from "../artifacts/nail06.jpg"

function Services() {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const animationDropFromTop = {
        initial: { opacity: 0, y: -50, transition: { duration: 1.5 } },
        animate: { opacity: 1, y: 0, transition: { duration: 1.5 } },
    };
    const animationRightToLeft = {
        initial: { opacity: 0, x: 25, transition: { duration: 1.5 } },
        animate: { opacity: 1, x: 0, transition: { duration: 1.5 } },
    };
    const handleLearnMoreClick = () => {
        const targetElement = ref3.current;
        const yOffset = -50;
        const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });

    };
    
    return (
        <div className="services">
            <div style={{backgroundImage: `url(${nail20})`, marginBottom: '50px'}} id="service-background"> 
                <div className="content-wrapper">
                    <motion.div ref={ref1}  variants={animationDropFromTop} initial="initial" whileInView="animate" style={{marginRight: '10px'}}>
                        <h1 style={{marginBottom: 0}}> Luxor Nails and Spa </h1> 
                        <h3 style={{marginTop: 0, color: 'black'}}> Experience Elevated Style with us</h3>
                    </motion.div>
                </div>
            </div>

            <motion.h1 ref={ref2}  variants={animationRightToLeft} initial="initial" whileInView="animate" style={{marginBottom: 0}}> 
                SERVICES
            </motion.h1>
            <h4 style={{margin: 0, textAlign: 'center'}}> Luxor Nails and Spa</h4>
            <p style={{marginTop: '5px', color: 'grey', textAlign: 'center'}}> North Reading, Massachusetts</p>
            <p style={{marginBottom: '50px'}}> Discover North Reading's favorite family-friendly nail oasis! Specializing in manicures, pedicures, and luxuries design for you, we ensure a pampering experience for all ages. Elevate your nail care routine with our exceptional services. Your journey to dazzling nails starts here!</p>
            <div className="service-menu">
                <div id="service-menu-block1">
                    <div className="column">
                        <div className="child child1">
                            <InfoCard menu = {manicure} title= 'Manicure' />
                            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <button style={{backgroundColor: 'white', marginBottom: '20px', color: 'black', boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}} onClick={handleLearnMoreClick}>Learn More</button>
                        </div>
                        </div>
                        <div className="child child2">
                            <InfoCard menu = {dipping_powder} title= 'Dipping Powder' />
                        </div>
                    </div>
                    <div className="column">
                        <div className="child child3">
                            <InfoCard menu = {acrylic} title= 'Acrylic' />
                            <InfoCard menu = {change_colors} title= 'Change Colors' />
                        </div>
                    </div>
                    <div className="child4" style={{width: '100%' }}>
                        <div style={{ width: '100%' }}>
                            <h2 style={{ height: '50px', textAlign: 'center' }}>Pedicure</h2>
                            <div style={{ width: '100%', overflow: 'auto'}}>
                                {
                                    Object.entries(pedicure).map(([service, details], key) => (
                                        <div key={key} id="service-pedicure">
                                            <p style={{ margin: 5, flex: '1', textAlign: 'left' }}>{service}</p>
                                            <p style={{ margin: 5, flex: '1', textAlign: 'left' }}>{details[0]}</p>
                                            <p style={{ margin: 5, flex: '1' }}>{details[1]}</p>
                                            <p style={{ margin: 5, flex: '1' }}>{details[2]}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
                            <button style={{backgroundColor: 'white', marginBottom: '10px', color: 'black', boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}} onClick={handleLearnMoreClick}>Learn More</button>
                        </div>
                    </div>

                    <div id ="child56">
                        <div className="child5">
                            <InfoCard menu = {other_pedicure_services} title= 'Other Pedicure Services' />
                        </div>
                        <div className="child6">
                            <InfoCard menu = {kids_services} title= 'Services For Kids' />
                        </div>
                    </div>
                </div>
            </div> 
            <div id="description">
                <motion.h1 ref={ref3}  variants={animationRightToLeft} initial="initial" whileInView="animate"> 
                    What we offer
                </motion.h1>
                <p> From expert manicures to luxurious pedicures, our talented technicians use only the highest quality products and techniques to pamper your nails to perfection. Whether you're seeking a classic polish or the latest nail art trends, our dedicated team is here to exceed your expectations. Unwind, refresh, and elevate your nail care experience with us, where beauty and sophistication converge.</p>
                <div style={{width: '100%', marginTop: '50px'}}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className = "containerSplitDouble">
                            <div className="inner-div" style={{ backgroundImage: `url(${nail2})`, backgroundSize: 'cover', borderRadius: '40px', boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px"}}>
                            </div>
                            <div className="inner-div textRightAlign">
                                <h2> Regular Manicure </h2>
                                <p> A traditional manicure involves shaping, filing, and buffing the nails, followed by cuticle care and a soothing hand massage. This classic treatment typically concludes with the application of regular nail polish in the color of your choice, offering a glossy finish that lasts for several days. </p>
                                <h2> Gel Manicure </h2>
                                <p> Gel manicures involve the application of a durable gel polish that is cured under a UV or LED lamp. This process ensures a chip-resistant, long-lasting finish that can endure up to two weeks without fading or chipping. Gel manicures offer a high-gloss shine and quick drying time, making them a popular choice for those seeking extended wear and minimal maintenance. </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div style={{ width: '100%', marginBottom: '100px' }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="containerSplitDouble">
                    <div className="inner-div textLeftAlign">
                        <h2> Luxor Pedicure </h2>
                        <p> A pedicure with paraffin involves the use of warm paraffin wax to deeply moisturize and soften the feet. After a standard pedicure, the feet are dipped into a warm paraffin bath, allowing the wax to coat the skin and lock in moisture. The soothing warmth helps to open pores and enhance the absorption of moisturizing agents, leaving the feet feeling incredibly soft and rejuvenated. This treatment is especially beneficial for dry, cracked heels and tired, achy feet. </p>
                        <div>
                            <h2> Pedicure Spa Level I </h2>
                            <p> Experience relaxation and rejuvenation with our Voesh Pedicure Spa Level I. In just 45 minutes, indulge in a pampering session that leaves your feet feeling refreshed and renewed. </p>
                        </div>
                        <div>
                            <h2> Pedicure Spa Level II </h2>
                            <p> Elevate your pedicure experience with our Volcano and Allure Pedicure Spa Level II. This 55-minute treatment combines luxury and comfort, ensuring your feet receive the care they deserve. </p>
                        </div>
                        <div>
                            <h2> Pedicure Spa Level III </h2>
                            <p> Treat your feet to the ultimate luxury with our Serum Spa Pedicure Spa Level III. Enjoy a 55-minute session designed to hydrate and nourish your skin, leaving you feeling pampered from heel to toe. </p>
                        </div>
                        <div>
                            <h2> Pedicure Spa Level IV </h2>
                            <p> Step up your self-care routine with our Pedicure Spa Level IV, featuring the addition of Gelly for an extra indulgent experience. In just 60 minutes, treat yourself to unparalleled relaxation and comfort. </p>
                        </div>
                        <div>
                            <h2> Pedicure Spa Level V </h2>
                            <p> Immerse yourself in pure bliss with our Special Spa Pedicure Spa Level V, enhanced with herbal treatments. This 60-minute session offers a holistic approach to foot care, leaving you feeling refreshed and revitalized. </p>
                        </div>
                    </div>
                    <div className="inner-div" style={{ backgroundImage: `url(${nail11})`, backgroundSize: 'cover', borderRadius: '40px', boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px"}}>
                    </div>
                </div>
            </div>
         
        </div>
        </div>
    
    );
}    

export default Services;


const manicure = {
    "Manicure - Regular": "$20",
    "Manicure - French" : "$25",
    "Manicure - Dazzle Dry": "$27",
    "Manicure - Gel/Shelac" : "$35",
    "Manicure - Gel French" : "$40",
    "Manicure - Gel X" : "$65&UP",
    "Soak Off Acrylic/Dipping for Others" : "$5",
    "Soak Off Acrylic/Dipping Only" : "$12",
    "Soak Off for Gel/Reg Manicure" : "$25"
}

const dipping_powder = {
    "Dipping Powder" : "$45",
    "Dipping Powder Refill": "$45",
    "Dipping Powder Full Set": "$55",
    "Dipping Powder - French or Pink/White": "$55",
    "Dipping Powder - French Full Set": "$55",
    "Dipping Powder - Ombre" : "$62",
    "Dipping Powder - Ombre Full Set": "$75"
}

const acrylic = {
    "Acrylic - Refill with Regular Polish" : "$35",
    "Acrylic - Full Set with Regular Polish" : "$45",
    "Acrylic - Refill with Gel" : "$45",
    "Acrylic - Full Set With Gel" : "$55",
}

const change_colors = {
    "Change Regular Polish" : "$15",
    "Change Polish French": "$20",
    "Change Dazzle Dry Polish": "$22",
    "Change Gel Polish" : "$28",
    "Change Gel French" : "$33",
    "Colors and up" : "$5",
    "Nail arts":  "$5, $15, $20"
}

const pedicure = {
    "Luxor Pedicure": ["Classic Pedicure Paraffine","30 to 35 mins", "$35"],
    "Gentlman Pedicure": ["", "30 to 35 mins", "$35"],
    "Pedicure Spa Level I" : ["Voesh", "45 mins", "$50"],
    "Pedicure Spa Level II" : ["Volcano, Allure", "55 mins", "$60"],
    "Pedicure Spa Level III" :["Serum Spa", "55 mins", "$65"],
    "Pedicure Spa Level IV" : ["Add Gelly", "60 mins", "$70"],
    "Pedicure Spa Level V" : ["Special spa with herbal", "60 mins", "$75"]
}

const other_pedicure_services = {
    "Add Dazzle Dry": "$8",
    "Add Gel Polish - No Soak Off" : "$15",
    "Add Gel Polish - Soak Off" : "$20",
    "Add French - No Soak Off": "$20",
    "Add French - Soak Off" : "$25",
    "Change Regular Polish" : "$25",
    "Change Reg Polish with Dazzle Dry" :  "$18",
    "Change Polish French" : "$23",
    "Change Gel": "$30",
    "Change Gel French": "$35"
}

const kids_services = {
    "Kid Manicure (7 years up)" : "$15",
    "Kid Pedicure (7 years up)": "$30",
    "Kid Gel Manicure (7 years up)": "$30",
    "Kid (Little Kid) Toes and Nails Painting" : "$19",
    "Kid Manicure and Pedicure (7 years or under)" : "$40"
}

