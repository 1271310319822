import { motion } from "framer-motion";
import "../styles/promo.css";
function PromoHeader() {
    const movingTextVariants = {
        initial: { x: '100vw' }, 
        animate: { x: '-150%' }, 
    };

    const transition = {
        duration: 20, 
        ease: "linear", 
        repeat: Infinity, 
    };
    return ( 
        <div className="movingtextContainer">
                <motion.p 
                    className="movingText"
                    variants={movingTextVariants}
                    initial="initial"
                    animate="animate"
                    transition= {{...transition}}
                    > 
                    Raffle to win $2,000 LOUIS VUITTON BAG. Ticket will be drawn on Jan 03, 2025. 
                </motion.p>

                
        </div>
     );
}

export default PromoHeader;