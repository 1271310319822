import { NavLink, Link } from "react-router-dom";
import { useState } from "react";
import "../styles/navigation.css";
import homeicon from "../artifacts/home.png";
import serviceicon from "../artifacts/services.png";
import galleriesicon from "../artifacts/galleries.png";
import contacticon from "../artifacts/contact.png";
import apppointmenticon from "../artifacts/appointment.png";
import giftCardIcon from "../artifacts/gift-card.png";
import luxorlogo from "../artifacts/luxorLogo.png";

function Navigation() {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return ( 
        <div className="navigation">
                <div className="bigscreen">
                    <div className="left"> 
                        <NavLink exact to="/" activeclassname="active">Home</NavLink>
                        <NavLink to="/services" activeclassname="active">Services</NavLink>
                        <NavLink to="/galleries" activeclassname="active">Galleries</NavLink>
                        <NavLink to="/contacts" activeclassname="active">Contact</NavLink>
                    </div>
                    <div className="mid"> 
                        <Link to="/"> <img src={luxorlogo} className="logomid" alt="luxor logo"/> </Link>
                    </div>
                    <div className="right"> 
                        <a href="https://www.fresha.com/a/luxor-nails-and-spa-north-reading-265-main-street-hl2asla0/all-offer?venue=true" > Gift Cards </a>
                        <a href="https://www.fresha.com/a/luxor-nails-and-spa-north-reading-265-main-street-hl2asla0/all-offer?venue=true" > Appointment </a>

                    </div>
                </div>
                <div className="mobilescreen">
                <div className="mobileView">
                    <Link to="/home" className="logo" > <img src={luxorlogo} alt="luxor Logo" className="logomid"/> </Link>
                    <div className="smallscreen">
                        <div className="burger" onClick={toggleMenu}>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                    </div>
                </div>
                </div>
            <div className={`menu ${showMenu ? 'open' : ''}`} onClick={toggleMenu}>

                <NavLink to="/home" className="navLinkWithIcon">
                    <img src={homeicon} alt="Home" className="icon" />
                    HOME
                </NavLink>
        
                <NavLink to="/services" className="navLinkWithIcon">
                    <img src={serviceicon} alt="Services" className="icon" />
                    SERVICES
                </NavLink>
                <NavLink to="/galleries" className="navLinkWithIcon">
                    <img src={galleriesicon} alt="Galleries" className="icon" />
                    GALLERIES
                </NavLink>
                <NavLink to="/contacts" className="navLinkWithIcon">
                    <img src={contacticon} alt="Contact" className="icon" />
                    CONTACT
                </NavLink>
                <a href="https://www.fresha.com/a/luxor-nails-and-spa-north-reading-265-main-street-hl2asla0/all-offer?venue=true" className="navLinkWithIcon">
                    <img src={apppointmenticon} alt="Appointment" className="icon" />
                    APPOINTMENT
                </a>
                <a href="https://www.fresha.com/a/luxor-nails-and-spa-north-reading-265-main-street-hl2asla0/all-offer?venue=true" className="navLinkWithIcon"> 
                    <img src={giftCardIcon} alt="Appointment" className="icon" />
                    GIFTS CARDS 
                </a>

            </div>


        </div>
     );
}

export default Navigation;
