import { motion } from "framer-motion";
import SocialMedia from "../components/socialMedia";
import BusinessHours from "../components/businesshour";
import telephoneIcon from "../artifacts/telephone.png";
import emailIcon from "../artifacts/email.png";
import Address from "./address";
import store002 from "../artifacts/design/store002.jpeg"
import { useRef } from "react";

function Contact() {
    const contactRef = useRef(null);
    const animationH1 = {
        initial: { opacity: 0, x: 25, transition: { duration: 1.5 } },
        animate: { opacity: 1, x: 0, transition: { duration: 1.5 } }, 
      };

    return ( 
        <section className="sectionAuto" ref={contactRef}>
                 <motion.h1 ref={contactRef} variants={animationH1} initial="initial" whileInView="animate" style={{fontSize: '32px', textAlign: 'left'}}>
                        Contact
                </motion.h1>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "0" }}>
                    <img src={emailIcon} alt="Email" style={{ marginRight: "10px", width: "20px", height: "20px" }} />
                    <p style={{ margin: "5px" }}>Email: Luxornail@gmail.com</p>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginBottom: '20px' }}>
                    <img src={telephoneIcon} alt="Telephone" style={{ marginRight: "10px", width: "20px", height: "20px" }} />
                    <p style={{ margin: "5px" }}> Phone: +1 (978) 664 - 1062</p>
                </div>


                <button id = "buttonStyle" style={{marginLeft: 0}}><a href="tel:9786641062" style={{ textDecoration: 'none', color: 'black'}}> Call Now </a> </button>
                <div style={{marginTop: '50px', display: 'flex', flexWrap: 'wrap'}}>      
                    <div className="bigCard2" style={{backgroundImage: `url(${store002})`, backgroundSize: 'cover'}}>
                    </div> 
                  
                    <div className="bigCard contact-box" >
                        <BusinessHours/>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}>
                        <div>
                            <h3 style={{textAlign: 'left', marginBottom: '20px'}}> Follow Us</h3>
                                <SocialMedia/>
                            </div>
                        </div>
                    </div>    
                </div>
                <motion.h1 ref={contactRef} variants={animationH1} initial="initial" whileInView="animate" style={{fontSize: '32px', textAlign: 'left'}}>
                        Address
                </motion.h1>
                <Address addressRef={contactRef} animationH1={animationH1}/>
        </section>

     );
}

export default Contact;