import "../styles/AppMain.css"
import "../styles/footer.css"
import fbIcon from "../artifacts/facebookblk.png"
import igIcon from "../artifacts/instagramblk.png"

function Footer() {
  return (
    <div className="footer" style={{ position: "relative", height: '30px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <p style={{ textAlign: "center" }}> © 2024 Luxor Nails and Spa </p>
    <div style={{width: '80px', display: 'flex', justifyContent: 'space-between'}}>
        <a href="https://www.facebook.com/LuxorNailsMA/">
            <img
                src={fbIcon}
                alt="facebook icon"
                width="30"
                height="30"
                style={{ borderRadius: "50%" }}
            />
        </a>
        <a href="https://www.instagram.com/luxornails_north_reading_ma/?hl=en">
            <img
                src={igIcon}
                alt="instagram icon"
                width="30"
                height="30"
                style={{ borderRadius: "50%" }}
            />
        </a>
    </div>

      {/* <div className="footer-content">
        <div style={{ flex: "1", textAlign: "center", marginBottom: "20px" }}>
          <img
            src={favicon}
            alt="luxornail icon"
            width="100"
            height="100"
            style={{ borderRadius: "50%" }}
          />
          <h3> Luxor Nails and Spa</h3>
          <p> 265 Main Street, 11, North Reading, Massachusetts </p>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={emailIcon}
                  alt="Email"
                  style={{ marginRight: "5px", width: "20px", height: "20px" }}
                />
                <p style={{ margin: "5px" }}>Email: Luxornail@gmail.com</p>
              </div>
              <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                <img
                  src={telephoneIcon}
                  alt="Telephone"
                  style={{ marginRight: "5px", width: "20px", height: "20px" }}
                />
                <p style={{ margin: "5px" }}>Phone: +1 (978) 664 - 01864</p>
              </div>
            </div>
          </div>
        </div>
        <div id="footer-line"> </div>
        <div id="footer-line"> </div>
        <div style={{ flex: "1" }} id="footer-directory">
          <div style={{ flex: "1" }} id="footer-directory">
            <div>
              <h3> Directory </h3>
              <div className="footer-nav-links">
                <NavLink to="/home" activeclassname="active" className="footer-link">
                  Home
                </NavLink>
                <NavLink to="/services" activeclassname="active" className="footer-link">
                  Services
                </NavLink>
                <NavLink to="/galleries" activeclassname="active" className="footer-link">
                  Galleries
                </NavLink>
                <NavLink to="/technicians" activeclassname="active" className="footer-link">
                  Technicians
                </NavLink>
                <a
                  href="https://www.fresha.com/a/luxor-nails-and-spa-north-reading-265-main-street-hl2asla0/all-offer?venue=true"
                  className="footer-link"
                >
                  Appointment
                </a>
              </div>
              <div id="footer-line"></div>
              <div id="footermedia">
                <div>
                  <h3 style={{ textAlign: "left", marginBottom: "20px" }}> Follow Us</h3>
                  <SocialMedia />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p style={{ textAlign: "center", marginBottom: 0 }}> © 2024 Luxor Nails and Spa </p>
      <p style={{ textAlign: "center", marginTop: "5px" }}> All Rights Reserved</p> */}
    </div>
  );
}

export default Footer;
