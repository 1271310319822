import "../styles/welcomecontent.css"
import { motion } from "framer-motion";
import luxorlogo from "../artifacts/luxorLogo.png"


function WelcomeContent() {
      return (
        <div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div>
                <img src ={luxorlogo} alt="luxornailandspa logo" width= '250vw' height= '250vw' />
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <LoadingBubble />
                </div>
            </div>
        </div>
        
      )
}

export default WelcomeContent;


const LoadingBubble = () => {
  const bubbleVariants = {
    hidden: {
      opacity: 0,
      scale: 0,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.8,
        ease: 'easeInOut',
        repeat: Infinity,
        repeatType: 'reverse',
        delay: 0.2 // This delay applies to each bubble individually
      },
    },
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 100" width="250" height="100">
      <motion.circle
        cx="25"
        cy="50"
        r="10"
        fill="#CD7F32"
        variants={bubbleVariants}
        initial="hidden"
        animate="visible"
        custom={0}
      />
      <motion.circle
        cx="75"
        cy="50"
        r="10"
        fill="#B6853E"
        variants={bubbleVariants}
        initial="hidden"
        animate="visible"
        custom={0.15} // Adding a slight delay for the middle bubble
      />
      <motion.circle
        cx="125"
        cy="50"
        r="10"
        fill="#CD7F32"
        variants={bubbleVariants}
        initial="hidden"
        animate="visible"
        custom={0.3} // Adding more delay for the rightmost bubble
      />
    </svg>
  );
};


// const LoadingBubble = () => {
//   const bubbleVariants = {
//     hidden: {
//       opacity: 0,
//       scale: 0,
//     },
//     visible: {
//       opacity: 1,
//       scale: 1,
//       transition: {
//         duration: 0.8,
//         ease: 'easeInOut',
//         repeat: Infinity,
//         repeatType: 'reverse',
//       },
//     },
//   };

//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="250" height="250">
//       <motion.circle
//         cx="50"
//         cy="50"
//         r="30"
//         // fill="#48abe0"
//         fill="#CD7F32"
//         variants={bubbleVariants}
//         initial="hidden"
//         animate="visible"
//       />
//       <motion.circle
//         cx="50"
//         cy="50"
//         r="20"
//         // fill="#48abc0"
//         fill="#B6853E"
//         variants={bubbleVariants}
//         initial="hidden"
//         animate="visible"
//       />
//     </svg>
//   );
// };


