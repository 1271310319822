import { motion } from "framer-motion";
import { useRef } from "react";
import CustomCards from "../components/CustomCards";
import nailtech1 from "../artifacts/nailtech1.jpg"
import "../styles/technician.css";
import CustomButton from "../components/CustomButton";
const appointmentLink = "https://www.fresha.com/a/luxor-nails-and-spa-north-reading-265-main-street-hl2asla0/all-offer?venue=true"

function Technicians() {
    const ref1 = useRef(null);
    const animationRightToLeft = {
        initial: { opacity: 0, x: 25, transition: { duration: 1.5 } },
        animate: { opacity: 1, x: 0, transition: { duration: 1.5 } },
    };

    return (
        <div>
            <motion.h1 ref={ref1} variants={animationRightToLeft} initial="initial" whileInView="animate">
                Our Team Members
            </motion.h1>
            <p>
                Our nail technicians are artists at heart, transforming every treatment into a masterpiece. With expert
                precision in manicures and pedicures, they specialize in bringing the latest trends and classic styles
                to your fingertips. Friendly and professional, they're dedicated to providing a relaxing experience,
                ensuring each client leaves with nails that not only look beautiful but feel rejuvenated. Passionate
                about nail health and design, our technicians are here to make your nail dreams come true.
            </p>
            <CustomButton backgroundColor='white' link={appointmentLink} text="Book Now" color= 'white'/>
            <div className="cards-container">

                {tech.map((technician, index) => (
                    <div key={index} className="card-wrapper">
                        <CustomCards data={technician} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Technicians;


const tech = [
    {
        "name": "John Doe",
        "Phone": "123456789",
        "image": nailtech1
    },
    {
        "name": "John Doe",
        "Phone": "123456789",
        "image": nailtech1
    },
    {
        "name": "John Doe",
        "Phone": "123456789",
        "image": nailtech1
    },
    {
        "name": "John Doe",
        "Phone": "123456789",
        "image": nailtech1
    },
    {
        "name": "John Doe",
        "Phone": "123456789",
        "image": nailtech1
    },
    {
        "name": "John Doe",
        "Phone": "123456789",
        "image": nailtech1
    },
    {
        "name": "John Doe",
        "Phone": "123456789",
        "image": nailtech1
    },
    {
        "name": "John Doe",
        "Phone": "123456789",
        "image": nailtech1
    },
    {
        "name": "John Doe",
        "Phone": "123456789",
        "image": nailtech1
    },
    {
        "name": "John Doe",
        "Phone": "123456789",
        "image": nailtech1
    },
    {
        "name": "John Doe",
        "Phone": "123456789",
        "image": nailtech1
    }
]