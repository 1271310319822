function InfoCard({menu, title}) {
    return (
        <div style={{width: '100%'}} className= "infoCard">
            <div style={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <h2> {`${title}`} </h2>
            </div>
            {
                Object.entries(menu).map(([service, price], key) => (
                <div key={key} id= "alignChild">
                    <p style={{margin: 0}}>{service}</p>
                    <p style={{margin: 0}}> {price} </p>
                </div>
                ))
            }
        </div>
    );
}

export default InfoCard;