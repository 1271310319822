// AppleMapsDirections.jsx
import React from 'react';

const AppleMapsDirections = ({ destination }) => {
  const url = `https://maps.apple.com/?daddr=${encodeURIComponent(destination)}&dirflg=d`;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
      <button id="buttonStyle" style={{marginLeft: 0, textDecoration: 'none' }}>Direction</button>
    </a>
  );
};

export default AppleMapsDirections;
