import { useState } from "react";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "../styles/gallaries.css";

export default function ReactImageGallery({images}) {
  const [index, setIndex] = useState(-1);

  return (
    <>
      <PhotoAlbum photos={images} layout="rows" targetRowHeight={300} onClick={({ index }) => setIndex(index)}  
        renderPhoto={({ images, wrapperStyle, renderDefaultPhoto }) => (
          <div style={{ ...wrapperStyle, borderRadius: '20px', overflow: 'hidden' }}>
            {renderDefaultPhoto({ wrapped: true })}
          </div>
        )}
      />

      <Lightbox
        slides={images}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        className="lightboxImage"
      />
      
    </>
  );
}