import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom'; // Import useLocation hook
import Navigation from './navigations/navigation';
import AppRoute from './navigations/appRoute';
import WelcomeContent from './components/welcome';
import "./styles/AppMain.css"
import Footer from './views/footer';


function ScrollToTop() {
  const { pathname } = useLocation(); // Get current pathname

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top whenever pathname changes
  }, [pathname]);

  return null; // ScrollToTop doesn't render any UI
}

function App() {
  const [showWelcome, setShowWelcome] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
        setShowWelcome(false);
    }, 2000); 

    return () => clearTimeout(timer);
  }, []);

  if (showWelcome) {
    return (
      <div className="welcome-content">
        <WelcomeContent/>
      </div>
    );
  } else {
    return(
      <div className='AppMain'>
        <BrowserRouter>
          <ScrollToTop /> {/* Add ScrollToTop component */}
          <Navigation/>
          <div style={{width: '100vw', display: 'flex', justifyContent: 'center', minHeight: '80vh'}}>
            <div style={{width: '93%', maxWidth: '1600px'}}>
              <AppRoute/>
            </div>
          </div>
          <Footer/>
        </BrowserRouter>
      </div>
    )
  }
}

export default App;
