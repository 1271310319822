import "../styles/cards.css";
function CustomCards({data}) {
    return ( 
        <div className="cards" style={{backgroundImage: `url(${data.image})`}}>
            <div className="cardsInfo">
                <div>
                    <p style={{fontWeight: 'bold'}}> {data.name}</p>
                    <p> Mobile : {data.Phone}</p>
                </div>
            </div>
        </div>
     );
}

export default CustomCards;