import { motion } from "framer-motion";
function AboutUs({aboutUsRef, animationH1}) {
    return ( 

            <div className="homepage-aboutus" style={{marginTop: '50px'}}>
                     <motion.h1 ref={aboutUsRef} variants={animationH1} initial="initial" whileInView="animate" style={{marginBottom: 0}}>
                        About Us
                    </motion.h1>
                    <h4 style={{margin: 0, textAlign: 'center'}}> Luxor Nails and Spa </h4>
                    <p style={{marginTop: 5, color: 'grey', textAlign: 'center'}}> North Reading, Massachusetts</p>
                    <p> Welcome to Luxor Nails and Spa's premier nail oasis, where indulgence meets impeccable service. Step into our modern space, where every detail is crafted to ensure your ultimate comfort. From expert manicures to luxurious pedicures, our talented technicians use only the highest quality products and techniques to pamper your nails to perfection. Whether you're seeking a classic polish or the latest nail art trends, our dedicated team is here to exceed your expectations. Unwind, refresh, and elevate your nail care experience with us, where beauty and sophistication converge. </p>
            </div>
     );
}

export default AboutUs;

