import { useRef } from "react";
import { motion } from "framer-motion";
import "../styles/gallaries.css"
// import CustomButton from "../components/CustomButton";
import d1 from "../artifacts/design/desgin001.jpeg";
import d2 from "../artifacts/design/design002.jpeg";
import d3 from "../artifacts/design/desgin003.jpeg";
import d4 from "../artifacts/design/design004.jpeg";
import d5 from "../artifacts/design/design005.jpeg";
import d6 from "../artifacts/design/design006.jpeg";
import d7 from "../artifacts/design/design007.jpeg";
import d8 from "../artifacts/design/design008.jpeg";
import d9 from "../artifacts/design/design009.jpeg";
import d10 from "../artifacts/design/design010.jpeg";
import d11 from "../artifacts/design/design011.jpeg";
import d12 from "../artifacts/design/design012.jpg";
import d13 from "../artifacts/design/design013.jpg";
import d14 from "../artifacts/design/design014.jpg";
import d15 from "../artifacts/design/design015.jpg";
import d16 from "../artifacts/design/design016.jpg";
import d17 from "../artifacts/design/design017.jpg";
import d18 from "../artifacts/design/design018.jpg";
import d19 from "../artifacts/design/design019.jpg";
import d20 from "../artifacts/design/design020.jpg";
import d21 from "../artifacts/design/design021.jpg";
import d22 from "../artifacts/design/design022.jpg";
import d23 from "../artifacts/design/design023.jpg";


import fa1 from "../artifacts/others/i1.jpg";
import fa2 from "../artifacts/others/i2.jpg";
import fa3 from "../artifacts/others/i3.jpeg";
import fa4 from "../artifacts/others/i4.png";
import fa5 from "../artifacts/others/i5.png";
import fa6 from "../artifacts/others/i6.jpg";
import fa7 from "../artifacts/others/i7.png";
import fa8 from "../artifacts/others/i8.png";
import fa9 from "../artifacts/others/i9.png";
import fa10 from "../artifacts/others/i10.png";
import fa11 from "../artifacts/others/i11.png";
import fa12 from "../artifacts/others/i12.png";

import v1 from "../artifacts/design2/d1.jpg";
import v2 from "../artifacts/design2/d2.jpg";
import v3 from "../artifacts/design2/d3.jpg";
import v4 from "../artifacts/design2/d4.jpg";
import v5 from "../artifacts/design2/d5.jpg";
import v6 from "../artifacts/design2/d6.jpg";
import v7 from "../artifacts/design2/d7.jpg";
import v8 from "../artifacts/design2/d8.jpg";
import v9 from "../artifacts/design2/d10.jpg";
import nail12 from "../artifacts/nail12.jpg";

import "../styles/technician.css";
import SocialMedia from "../components/socialMedia";
import CustomButton from "../components/CustomButton";
import ReactImageGallery from "../components/reactImageLightBox";


function Galleries() {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref4 = useRef(null);
    const appointmentLink = "https://www.fresha.com/a/luxor-nails-and-spa-north-reading-265-main-street-hl2asla0/all-offer?venue=true";
    const animationRightToLeft = {
        initial: { opacity: 0, x: 25, transition: { duration: 1.5 } },
        animate: { opacity: 1, x: 0, transition: { duration: 1.5 } },
    };
    const imagesReact = [

        { src: d12, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d13, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d14, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d15, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d16, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d17, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d18, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d19, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d20, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d21, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d22, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d23, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d1, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d2, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d3, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d4, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d5, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d6, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d7, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d8, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d9, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d10, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: d11, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: v1, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: v2, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: v3, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: v4, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: v5, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: v6, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: v7, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: v8, width: 350, height: 300, alt: "luxornailsandspa images" },
        { src: v9, width: 350, height: 300, alt: "luxornailsandspa images" }
      ];
    const othersReact = [
        { src: fa1, width: 350, height: 300, alt: "Luxor Nails and Spa images" },
        { src: fa2, width: 350, height: 300, alt: "Luxor Nails and Spa images" },
        { src: fa3, width: 350, height: 300, alt: "Luxor Nails and Spa images" },
        { src: fa4, width: 350, height: 300, alt: "Luxor Nails and Spa images" },
        { src: fa5, width: 350, height: 300, alt: "Luxor Nails and Spa images" },
        { src: fa6, width: 350, height: 300, alt: "Luxor Nails and Spa images" },
        { src: fa7, width: 350, height: 300, alt: "Luxor Nails and Spa images" },
        { src: fa8, width: 350, height: 300, alt: "Luxor Nails and Spa images" },
        { src: fa9, width: 350, height: 300, alt: "Luxor Nails and Spa images" },
        { src: fa10, width: 350, height: 300, alt: "Luxor Nails and Spa images" },
        { src: fa11, width: 350, height: 300, alt: "Luxor Nails and Spa images" },
        { src: fa12, width: 350, height: 300, alt: "Luxor Nails and Spa images" }
      ];
      
      

    return ( 
    <div className="galleries">

        <div style={{backgroundImage: `url(${nail12})`, backgroundSize: 'cover', width: "100%", height: "600px", borderRadius: '40px', display: 'flex', justifyContent: "flex-start", alignItems: 'flex-end', marginBottom: '50px'}}>
            <div style={{padding: '10px', margin: '7%', fontSize: '15px'}} id = "titleBackgroundGlass">
                <motion.h1 ref={ref1} variants={animationRightToLeft} initial="initial" whileInView="animate" style={{marginBottom: 0, textAlign: 'left'}}>
                    Luxor Nails and Spa
                </motion.h1>
                <motion.h3 ref={ref1} variants={animationRightToLeft} initial="initial" whileInView="animate" style={{marginTop: 0}}>
                    Where Beauty Begins, From Tips to Toes
                </motion.h3>
                <CustomButton backgroundColor='white' link={appointmentLink} text="Book Now" color= 'black'/>
            </div>
        </div>

        <div> 
                <motion.h1 ref={ref2} variants={animationRightToLeft} initial="initial" whileInView="animate" style={{marginBottom: 0}}>
                    Galleries
                </motion.h1>
                <h3 style={{color: 'black', margin: 0, textAlign: 'center'}}> Luxor Nails and Spa </h3>
                <p style={{color: 'grey', margin: 5, textAlign: 'center'}}> North Reading, Massachusetts </p>
                <p> Welcome to our nail salon, where beauty meets relaxation! Step into our haven of pampering and treat yourself to the ultimate nail care experience. Whether you're in need of a meticulous manicure, a rejuvenating pedicure, or a stunning nail design that reflects your unique style, our expert technicians are here to cater to your every need.

                    But it's not just for adults – we also specialize in providing top-notch nail services for kids, ensuring that even the little ones can enjoy a touch of luxury and style. Our kid-friendly treatments are gentle, fun, and tailored to their preferences, making every visit to our salon a delightful experience for both children and parents alike. </p>
                
                <h4> Follow us on social media for more</h4>   
                <SocialMedia/>
                <div style={{marginTop: '50px'}}>
                    <ReactImageGallery images={imagesReact}/>
                </div>
                
        </div>
        <div style={{marginTop: '50px'}}> 
                <motion.h1 ref={ref4} variants={animationRightToLeft} initial="initial" whileInView="animate">
                    Facilities & Amenities
                </motion.h1>
                <p> Experience convenience and luxury at our nail salon. Enjoy hassle-free parking, seamless cashless payments, and pristine lavatory facilities. Our salon boasts top-quality equipment for superior service. Plus, indulge in complimentary refreshments and amenities for the ultimate pampering experience.</p>
                <div style={{marginTop: '50px'}}>
                    <ReactImageGallery images={othersReact}/>
                </div>

        </div>

    </div> 
    );
}

export default Galleries;

