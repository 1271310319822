function BusinessHours() {
    return ( 

        <div>
            <h3> Business Hours </h3>
            <ul className="opening-hours">
                <li>Monday<span>9:30 AM - 7:00 PM</span></li>
                <li>Tuesday<span>9:30 AM - 7:00 PM</span></li>
                <li>Wednesday<span>9:30 AM - 7:00 PM</span></li>
                <li>Thursday<span>9:30 AM - 7:00 PM</span></li>
                <li>Friday<span>9:30 AM - 7:30 PM</span></li>
                <li>Saturday<span>9:30 AM - 6:00 PM</span></li>
                <li>Sunday<span>10:00 AM - 5:00 PM</span></li>
            </ul>
        </div>
     );
}

export default BusinessHours;