import React from "react";
import {Routes, Route } from "react-router-dom";
import Home from "../views/home";
import Services from "../views/services";
import Technicians from "../views/technicians";
import Galleries from "../views/galleries";
import Contact from "../views/contact";

function AppRoute() {
    return ( 
        <div>
            <Routes>
                <Route path="" element={<Home />}/>
                <Route path="/home" element={<Home />} />
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<Services/>} />
                <Route path="/technicians" element={<Technicians/>} />
                <Route path="/galleries" element={<Galleries/>} />
                <Route path="/contacts" element={<Contact/>} />
            </Routes>
        </div>
     );
}

export default AppRoute;