import React from "react";
import { useRef, useState,useEffect } from "react";
import { motion} from "framer-motion";
import nail6 from "../artifacts/nail06.jpg"
import nail8 from "../artifacts/nail08.png"
import nail10 from "../artifacts/nail10.png"
import mani004 from "../artifacts/mani004.jpg"
import nail14 from "../artifacts/nail14.png"
import nail15 from "../artifacts/nail15.jpg"
import nail17 from "../artifacts/nail17.jpg"
import nail18 from "../artifacts/nail18.jpg"

import parkingIcon from "../artifacts/parking.png"; 
import chairIcon from "../artifacts/chair.png";
import tvIcon from "../artifacts/monitor.png";
import lavatoryIcon from "../artifacts/bathroom.png";
import drinkIcon from "../artifacts/soft-drink.png";
import cashLessIcon from "../artifacts/cashless-payment.png";
import giftcardIcon from "../artifacts/gift-card.png";
import {NavLink } from "react-router-dom";
import AboutUs from "./aboutus";
import Promocard from "../components/promocard";
import PromoHeader from "../components/promoheader";


const Home = () => {
    const containerRef = useRef(null);
    const serviceRef = useRef(null);
    const aboutUsRef = useRef(null);
    const ref4 = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const appointmentLink = "https://www.fresha.com/a/luxor-nails-and-spa-north-reading-265-main-street-hl2asla0/all-offer?venue=true"
    const homeImages = [
        { image: nail17, title: 'LUXOR NAILS AND SPA', lowersub: 'North Reading, MA', uppersub: 'Welcome', color: '#8B4513' },
        { image: nail14, title: 'LUXOR NAILS AND SPA', lowersub: 'North Reading, MA', uppersub: 'Visit Us', color: 'white' },
        { image: nail6, title: 'LUXOR NAILS AND SPA', lowersub: 'Make your nails shine', uppersub: 'Experiences', color: '#8B4513' },
        { image: nail15, title: 'LUXOR NAILS AND SPA', lowersub: 'Make your nails shine', uppersub: 'Visit Us', color: 'white' },
    ];

    const animationH1 = {
        initial: { opacity: 0, x: 25, transition: { duration: 1.5 } },
        animate: { opacity: 1, x: 0, transition: { duration: 1.5 } }, 
      };
      
    

    const handleScroll = () => {
        const scrollLeft = containerRef.current.scrollLeft;
        const newIndex = Math.round(scrollLeft / containerRef.current.offsetWidth);
        setCurrentIndex(newIndex);
    };
    useEffect(() => {
        const element = containerRef.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
            return () => element.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newIndex = (currentIndex + 1) % homeImages.length;
            scrollToIndex(newIndex);
        }, 5000); /* adjust the image sliding speed */
        return () => clearInterval(intervalId);
    }, [currentIndex, homeImages.length]);

    const scrollToIndex = (index) => {
        containerRef.current.scrollTo({
            left: index * containerRef.current.offsetWidth,
            behavior: 'smooth',
        });
    };

    const serviceImage =[
        { image: nail8, title: 'Desgin'},
        { image: mani004, title: 'Manicure'},
        { image: nail18, title: 'Pedicure'},
        { image: nail10, title: 'Kids'}
    ]


    return (
        <div className="sectionAuto">
            <PromoHeader/>
            <motion.div className="section">
                <div className="homeSection" style={{ display: 'flex', scrollSnapType: 'x mandatory', width: '100%', overflowX: 'scroll', overflowY: 'hidden' }} ref={containerRef}>
                    {homeImages.map((item, index) => (
                        <div key={index} style={{ backgroundImage: `url(${item.image})`, backgroundSize: 'cover', backgroundPosition: 'center', minWidth: '100%', flex: '0 0 auto', scrollSnapAlign: 'start', cursor: 'pointer' }} onClick={() => scrollToIndex((currentIndex + 1) % homeImages.length)}>
                            <div style={{width: '100%', height: '40%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <div style={{width: '60%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <div style={{textAlign: 'center', color: 'white', fontWeight: 'bold'}} className="animatedObjectRightToLeft">
                                        <h2 style={{marginBottom: 0}} id='homebackgroundh1'>{item.uppersub}</h2>
                                        <h1 style={{margin: 0, color: item.color, fontWeight: 'bolder'}} id='homebackgroundh1'>{item.title}</h1>
                                        <h3 style={{margin: 0, fontWeight: 'bold'}} id='homebackgroundp'>{item.lowersub}</h3>
                                    </div>
                                </div>
                            </div>
                    
                        </div>
                    ))}
                </div>

                <div style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -8vh)', zIndex: 1000 }}>
                    <button style={{ backgroundColor: 'white'}}>
                        <a href={appointmentLink} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'black'}}>Book Now</a>
                    </button>
                </div>
                <div style={{ position: 'absolute', left: '50%', transform: 'translate(-47%, -3vh)', zIndex: 1000, display: 'flex', alignItems: 'center' }}>
                    {[...Array(homeImages.length).keys()].map((index) => (
                        <div key={index} style={{ width: '20px', height: '5px', borderRadius: '20px', marginRight: '10px', cursor: 'pointer' , backgroundColor: currentIndex === index ? '#8B4513' : 'white'}} onClick={() => scrollToIndex(index)}/>
                    ))}
                </div>

            <Promocard/>
            </motion.div>
            <AboutUs aboutUsRef={aboutUsRef} animationH1 = {animationH1}/>
            <div className="sectionAuto flexVertical">
                <div className="homepage-service">
                    <motion.h1 ref={serviceRef} variants={animationH1} initial="initial" whileInView="animate">
                        Services
                    </motion.h1>
                    <p> Indulge in a touch of luxury at North Reading's premier nail salon! Offering a wide range of services including manicures, pedicures, exclusive designs and other’s services for kids, we're here to pamper every member of the family. Experience beauty and care beyond compare. Visit us today for an unforgettable nail spa journey!  
                                Check out our <NavLink to= "/services" style={{ textDecoration: 'underline', color: '#8B4513'}}> services </NavLink> here
                    </p>
                    <div className="home-feature-services">
                        {serviceImage.map((item, index) => (  
                        <div className="card" key={index}>
                            <div className="card" style={{backgroundImage: `url(${item.image})`, backgroundSize: 'cover', margin: 0, height: '300px', display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>
                                <NavLink to="/services" id = "homeMiniServices" className="active" style={{width: '100%', margin: '8px', backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '10px', textDecoration: 'none'}}>
                                     <button style={{backgroundColor: 'transparent', color: 'black', fontSize: '16px'}}> {`${item.title}`} </button>
                                </NavLink>
                            </div>
                        </div>
                        ))}
                     </div>
                </div>
            </div>
                <Facilities ref4={ref4} animationH1 ={animationH1}/>
            </div>
            );
        };

        export default Home;

function Facilities({ ref4, animationH1 }) {
    return (
        <div>
            <motion.h1 ref={ref4} variants={animationH1} initial="initial" whileInView="animate">
                Amenities
            </motion.h1>
            <p> At Luxor Nails and Spa, we pride ourselves on providing an array of luxurious amenities to enhance your experience. Enjoy the convenience of our free parking facilities, ensuring hassle-free arrival for all our valued guests. We accommodate various payment preferences, accepting both cash and cashless transactions for your convenience. Indulge in ultimate comfort with our state-of-the-art equipment, including deluxe pedicure chairs designed to elevate your relaxation. Experience pampering like never before at Luxor Nails and Spa, where every detail is crafted to offer you a truly luxurious escape.</p>
            <NavLink to="/galleries" id="buttonStyle" style={{ height: '25px', marginLeft: 0 }}>Learn More</NavLink>
            <div style={{ maxWidth: '500px', width: '100%', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridGap: '10px', marginTop: '50px', overflowX: 'scroll'}}>
                {columnItems}
            </div>
        </div>
    );
}


const items = [
    { image: parkingIcon, title: "Available Parking" },
    { image: cashLessIcon, title: "Cashless Available" },
    { image: chairIcon, title: "Pedicure Chair" },
    { image: tvIcon, title: "TV" },
    { image: giftcardIcon, title: "Gift Cards" },
    { image: lavatoryIcon, title: "Lavatory" },
    { image: drinkIcon, title: "Complimentary" }
];

const columnItems = items.map((item, index) => (
    <div key={index} style={{ textAlign: 'center', minWidth: '100px', minHeight: '50px', display: 'flex', alignItems: 'center'}}>
        <img src={item.image} alt={item.title} style={{ width: '30px', height: '30px', marginRight: '20px' }} />
        <p style={{ margin: '2px' }}>{item.title}</p>
    </div>
));

