import React from "react";
import "../styles/button.css";

function CustomButton({ backgroundColor, link, text }) {

    return (
        <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "black", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", borderRadius: '20px'}}>
            <button id="buttonStyles" style={{backgroundColor: backgroundColor, color: 'black'}} >
                {text}
            </button>
        </a>



    );
}

export default CustomButton;
