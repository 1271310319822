import locationIcon from "../artifacts/location.png";
import AppleMapsDirections from "../components/appledirection";
function Address() {

    const destination = '265 Main St, Unit 11, North Reading, MA 01864, United States';

    return ( 
        <div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <img src={locationIcon} alt="Location" style={{ marginRight: "10px", width: "20px", height: "20px" }} />
                {/* <p>265 Main Street, 11, North Reading, Massachusetts</p> */}
                <p> 265 Main St, #11, North Reading, MA 01864, United States</p>
            </div>
            {/* <a href="https://maps.app.goo.gl/Dd7YnaWSZeUdTPfs8" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                <button id="buttonStyle" style={{marginLeft: 0 }}>Direction</button>
            </a> */}
            <AppleMapsDirections destination={destination} />

            <div style={{marginTop: '50px'}}>
                {/* <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2937.3021174285786!2d-71.11860368704406!3d42.591333171052014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3095f26baeefb%3A0x5bc85f8325eb3d6c!2sLuxor%20Nails%20%26%20Spa!5e0!3m2!1sen!2sus!4v1712868470401!5m2!1sen!2sus" 
                    width="100%" 
                    height="450" 
                    style={{border: "0"}} 
                    allowFullScreen 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade"
                    id = "map"
                    title="Luxor Nails & Spa Location"
                ></iframe> */}
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d734.3259496441264!2d-71.11677377149665!3d42.59129749819078!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e309d6bb21cd17%3A0xf7ac3f58b616fb2!2s265%20Main%20St%20%2311%2C%20North%20Reading%2C%20MA%2001864!5e0!3m2!1sen!2sus!4v1718112646541!5m2!1sen!2sus"  width="100%" 
                    height="450" 
                    style={{border:0}} 
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"
                    id = "map"
                    title="Luxor Nails & Spa Location"
                    
                ></iframe>
                
            </div>
        </div>
     );
}

export default Address;