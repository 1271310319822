import facebook from "../artifacts/facebookblk.png";
import instagram from "../artifacts/instagramblk.png";

function SocialMedia() {
    return ( 
        <div style={{}}>
            <div style={{width: '100%', display: 'flex'}}>
                <a href="https://www.facebook.com/LuxorNailsMA/"><img src={facebook} alt="facebook icon"  height='40' width='40' style={{marginRight: '30px'}}/></a>
                <a href="https://www.instagram.com/luxornails_north_reading_ma/?hl=en"> <img src={instagram} alt="instagram icon" height='40' width='40'/> </a>
            </div>
        </div>
     );
}

export default SocialMedia;